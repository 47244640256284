import { useEffect, useState } from "react";
import FideliteLayout from "../../../../components/layout/FideliteLayout";
import FormBox from "../../../../components/ui/formBox";
import { getFidelisationByMagcode, getFidelisationPacks, sendEmailWhenFidelityFormComplete, setFidelityData } from "../../../../services/fidelite_api";
import useAuth from "../../../../hooks/useAuth";
import { DOMAIN } from "../../../../config/env";
import CheckIcon from "../../../../components/ui/icons/CheckIcon";
import Loader from "../../../../components/ui/loader/Loader";
import { RESSOURCE } from "../../../../config/constants";

const FideliteInscription = () => {

    const { magasin } = useAuth();

    // Data loaded
    const [loadedData, setLoadedData] = useState(false);

    //Data packs array
    const [fidelisationPacks, setFidelisationPacks] = useState(null);

    //legalCheckbox
    const [legalNotice, setLegalNotice] = useState(false);

    //InvalidFields
    const [invalidFields, setInvalidFields] = useState(null)

    // formControl
    const [validForm, setValidForm] = useState(0);
    const [statusForm, setStatusForm] = useState(null);

    // pack
    const [selectedPack, setSelectedPack] = useState(1);

    // User form
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');

    // store
    const [storeName, setStoreName] = useState('');
    const [storeSiret, setStoreSiret] = useState('');
    const [storeAddress, setStoreAddress] = useState('');
    const [storeEmail, setStoreEmail] = useState('');
    const [storeSchedule, setStoreSchedule] = useState('');
    const [senderName, setSenderName] = useState('');

    // Web
    const [siteUrl, setSiteUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [instaUrl, setInstaUrl] = useState('');

    //file
    const [logoFile, setLogoFile] = useState('');
    const [sepaFile, setSepaFile] = useState('');
    const [ribFile, setRibFile] = useState('');
    const [dechargeFile, setDechargeFile] = useState('');

    //registered File

    const [registeredLogo, setRegisteredLogo] = useState('');
    const [registeredSepa, setRegisteredSepa] = useState('');
    const [registeredRib, setRegisteredRib] = useState('');
    const [registeredDecharge, setRegisteredDecharge] = useState('');

    // charger les données
    useEffect(() => {
        getFidelisationPacksData();
        getFidelisationMagData();
    }, [magasin])

    // Function to send File when change
    const handleFileChange = async (e, setFile, file_input_name) => {
        const selectedFile = e.target.files[0]

        // If form status is greater than 1, submission is not allowed
        if (statusForm && statusForm > 1) {
            console.error('envoie impossible')
            return
        }

        if (!selectedFile) {
            console.error("No file selected.");
            return;
        }

        setFile(selectedFile)

        const formData = new FormData();

        formData.append('mag_code', magasin.mag_code);
        formData.append(file_input_name, selectedFile);

        try {
            const data = await setFidelityData(formData);
            dataTraitement(data);
        } catch (error) {
            console.error("Une erreur est survenue lors du chargement du fichier");
        }
    };

    const handleSubmitForm = async () => {
        // If form status is greater than 1, submission is not allowed
        if (statusForm && statusForm > 1) {
            console.error('envoie impossible')
            return
        }
        // If legalNotice not true, submission is not allowed
        if (!legalNotice) {
            alert('Vous devez accepter les conditions générales de vente pour soumettre le formulaire.')
            return
        }

        try {
            // Form submission for validation
            const response = await handleSubmitData();
            setInvalidFields(response.invalidFields)

            // If form status is less than 1, submission is not allowed
            if (response.invalidFields.length < 1) {
                // We validate the form
                const subscriptionResponse = await handleValidSubscription()

                if (subscriptionResponse) {
                    // We sending mag code
                    const data = {
                        'mag_code': magasin.mag_code,
                    }
                    // We sending email
                    const emailResponse = await sendEmailWhenFidelityFormComplete(data)

                    if (!emailResponse.success) {
                        // send status to 1 for unvalided form
                        try {
                            await handleUnvalidSubscription();
                        } catch (error) {
                            console.error('Erreur lors de l\'invalidation du formulaire')
                        }
                    } else {
                        alert('Inscription prise en compte.')
                    }
                }
            }
        } catch (e) {
            console.error('Erreur de soumission du formulaire', e)
        }
    }

    // Function to change status to 2 (Validated)
    const handleValidSubscription = async () => {
        const formData = new FormData();

        formData.append('mag_code', magasin.mag_code);
        formData.append('id_statut', 2);

        try {
            const data = await setFidelityData(formData);
            dataTraitement(data);
            return data;
        } catch (error) {
            console.error('Erreur lors de la validation du formulaire')
        }
    }

    // Function to change status to 1 (Not validated)
    const handleUnvalidSubscription = async () => {
        const formData = new FormData();

        formData.append('mag_code', magasin.mag_code);
        formData.append('id_statut', 1);

        try {
            const data = await setFidelityData(formData);
            dataTraitement(data);
            return data;
        } catch (error) {
            console.error('Erreur lors de l\'invalidation du formulaire');
        }
    }

    // Function to submit data (Only text data)
    const handleSubmitData = async () => {
        // If form status is greater than 1, submission is not allowed
        if (statusForm && statusForm > 1) {
            return
        }
        const formData = new FormData();

        formData.append('mag_code', magasin.mag_code);
        formData.append('id_pack', selectedPack)
        formData.append('fid_uti_nom', lastname);
        formData.append('fid_uti_prenom', firstname);
        formData.append('fid_uti_mail', email);
        formData.append('fid_uti_tel', tel);

        formData.append('fid_magasin', storeName);
        formData.append('fid_siret', storeSiret);
        formData.append('fid_adresse', storeAddress);
        formData.append('fid_mail', storeEmail);
        formData.append('fid_horaires', storeSchedule);
        formData.append('fid_expediteur', senderName);

        formData.append('fid_url_site', siteUrl);
        formData.append('fid_url_insta', instaUrl);
        formData.append('fid_url_facebook', facebookUrl);

        try {
            const data = await setFidelityData(formData);
            dataTraitement(data);
            return data
        } catch (error) {
            console.error('Erreur lors de la soumission des données')
        }
    }


    /* Function to get all fidelisation pack data */
    const getFidelisationPacksData = async () => {
        try {
            const data = await getFidelisationPacks();
            if (data) {
                setFidelisationPacks(data);
            }
        } catch (error) {
            console.error(error)
        }
    }

    // Function to update state data upon receiving the returned data
    const dataTraitement = (data) => {

        if (data.id_statut) setStatusForm(data.id_statut)

        if (data.id_pack && !selectedPack || selectedPack != data.id_pack) setSelectedPack(parseInt(data.id_pack));
        if (data.fid_uti_mail) setEmail(data.fid_uti_mail);
        if (data.fid_uti_nom) setLastname(data.fid_uti_nom);
        if (data.fid_uti_prenom) setFirstname(data.fid_uti_prenom);
        if (data.fid_uti_tel) setTel(data.fid_uti_tel);

        if (data.fid_magasin) setStoreName(data.fid_magasin);
        if (data.fid_siret) setStoreSiret(data.fid_siret);
        if (data.fid_adresse) setStoreAddress(data.fid_adresse);
        if (data.fid_mail) setStoreEmail(data.fid_mail);
        if (data.fid_horaires) setStoreSchedule(data.fid_horaires);
        if (data.fid_expediteur) setSenderName(data.fid_expediteur);

        if (data.fid_url_site) setSiteUrl(data.fid_url_site);
        if (data.fid_url_insta) setInstaUrl(data.fid_url_insta);
        if (data.fid_url_facebook) setFacebookUrl(data.fid_url_facebook);

        if (data.fid_fichier_logo) setRegisteredLogo(data.fid_fichier_logo);
        if (data.fid_fichier_rib) setRegisteredRib(data.fid_fichier_rib);
        if (data.fid_fichier_sepa) setRegisteredSepa(data.fid_fichier_sepa);
        if (data.fid_fichier_decharge) setRegisteredDecharge(data.fid_fichier_decharge);

        if (data.fid_valid) setValidForm(data.fid_valid);
    }

    // UseEffect on selectedPack change to submit data
    useEffect(() => {
        // Wait for the data to be loaded before allowing the submit
        if (statusForm <= 1 && loadedData) {
            handleSubmitData()
        }
    }, [selectedPack])

    // Function to get Fidelity data for mag
    const getFidelisationMagData = async () => {
        try {
            const data = await getFidelisationByMagcode(magasin.mag_code);

            if (data.length < 1) {
                handleSubmitData();
            }

            if (data && !loadedData) {
                setLoadedData(true);
            }

            if (data.length > 0) {
                dataTraitement(data[0])
            }
        } catch (error) {
            console.error('Erreur lors du chargement de la page')
        }
    }

    // Show loader if data is still loading
    if (!loadedData) {
        return <FideliteLayout>
            <Loader />
        </FideliteLayout>
    }

    return <FideliteLayout>
        <div className="fidelity-form-container">

            {statusForm}
            <div>
                <FormBox
                    title="Choix du pack d'engagement"
                >
                    <div className="fidelity-pack-container">
                        <div className="pack-info">
                            <p>Ouverture et implémentation du dispositif par magasin. Uniquement pour une nouvelle adhésion,
                                par prélévement unique à l'ouverture à l'ouverture du compte
                            </p>
                            <p>500 € H.T</p>
                        </div>

                        <div className="pack-form-container">
                            <p className="container-pack-title">Selectionner votre pack {typeof (selectedPack)} {selectedPack}</p>

                            {
                                fidelisationPacks && fidelisationPacks.map(pack => {
                                    return (
                                        pack.pack_valid && pack.pack_valid == 1 && (
                                            <div className="container-pack-form-content" key={pack.id_pack}>
                                                <div className="content-pack-center">
                                                    <input type="radio" name="fidelisationSelected"
                                                        checked={pack.id_pack === selectedPack}
                                                        value={pack.id_pack}
                                                        onChange={(e) => {
                                                            setSelectedPack(parseInt(e.target.value))
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="content-pack-title">{pack.pack_libelle}</p>
                                                    <p>{pack.pack_description}</p>
                                                </div>
                                                <div className="content-pack-center">
                                                    <p>{pack.pack_prix_ht} € H.T* <br /> mensuel</p>
                                                </div>
                                            </div>
                                        )
                                    )
                                })
                            }

                        </div>

                        <div className="pack-legal">
                            <p>Engagement pour une durée initiale de 36 mois qui se renouvellera par tacite reconduction pour la même durée et aux conditions générales de vente d'Inédis Service disponible téléchargeable sur Extranet. Le paiement s'effectue par prélévement.</p>

                            <p>*Hors coût contact des récurrents (courrier ou email ou sms)</p>
                        </div>
                    </div>

                </FormBox>

                <FormBox
                    title="Utilisateur principal de la plateforme"
                >
                    <p className="header-paragraph">
                        Personne qui sera en charge du compte fidélité dans le ou les magasins<br />
                        (obligatoire pour l'envoi des codes d'accès)
                    </p>

                    <div>
                        <input type="text" placeholder="Nom" value={lastname} onChange={(e) => setLastname(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>
                    <div>
                        <input type="text" placeholder="Prénom" value={firstname} onChange={(e) => setFirstname(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>
                    <div>
                        <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>
                    <div>
                        <input type="text" placeholder="Téléphone" value={tel} onChange={(e) => setTel(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>

                </FormBox>
            </div>


            <div>
                <FormBox
                    title="Informations magasin à destination de vos clients"
                >
                    <p className="header-paragraph">Elle seront renseignées sur la plateforme fidélité de votre magasin et utilisées pour la création de vos communications à destination de vos clients.</p>

                    <p className="form-section-title">Vos coordonnées</p>

                    <div>
                        <input type="text" placeholder="Nom commercial" value={storeName} onChange={(e) => setStoreName(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>
                    <div>
                        <input type="text" placeholder="adresse" value={storeAddress} onChange={(e) => setStoreAddress(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>
                    <div>
                        <input type="email" placeholder="Email" value={storeEmail} onChange={(e) => setStoreEmail(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>
                    <div>
                        <textarea placeholder="Horaires d'ouverture" value={storeSchedule} onChange={(e) => setStoreSchedule(e.target.value)} onBlur={() => handleSubmitData()}>{storeSchedule}</textarea>
                    </div>
                    <div>
                        <input type="text" placeholder="Nom d'expéditeur (11 caractères maximum sans espaces)" value={senderName} onChange={(e) => setSenderName(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>

                    <p className="form-section-title">Web (Renseignez url si existant)</p>

                    <div>
                        <textarea placeholder="Lien site (Facultatif)" value={siteUrl} onChange={(e) => setSiteUrl(e.target.value)} onBlur={() => handleSubmitData()}>{siteUrl}</textarea>
                    </div>
                    <div>
                        <textarea placeholder="Lien Facebook (Facultatif)" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} onBlur={() => handleSubmitData()}></textarea>
                    </div>
                    <div>
                        <textarea placeholder="Lien Instagram (Facultatif)" value={instaUrl} onChange={(e) => setInstaUrl(e.target.value)} onBlur={() => handleSubmitData()}></textarea>
                    </div>
                    <div>
                        <p>
                            {
                                registeredLogo && <div>
                                    <img src={`${DOMAIN.URL_WEB}${RESSOURCE.FILE_FID}${registeredLogo}`} width={120} height={'auto'} />
                                </div>
                            }
                            Votre logo en haute définition (jpeg - png)
                            <label htmlFor="file_logo" className="button-primary" style={{ cursor: 'pointer' }}>Transmettre</label>
                            <input
                                type="file"
                                id="file_logo"
                                onChange={(e => {
                                    handleFileChange(e, setLogoFile, 'fid_fichier_logo');
                                })}
                                style={{ visibility: 'hidden' }}
                                accept=".jpeg, .jpg, .png,"
                            />
                        </p>

                    </div>
                </FormBox>
                <FormBox
                    title="Validation de la commande"
                >
                    <p className="header-paragraph">A complèter uniquement si INEDIS SERVICES ne dispose pas déjà de votre mandat de prélevement SEPA.</p>

                    <div>
                        <input type="text" placeholder=" N° SIRET" value={storeSiret} onChange={(e) => setStoreSiret(e.target.value)} onBlur={() => handleSubmitData()} />
                    </div>

                    <div className="legal-files-container">
                        <p>{registeredSepa && <CheckIcon />}  SEPA INEDIS SERVICES</p>
                        {
                            registeredSepa && <a href={`${DOMAIN.URL_WEB}${RESSOURCE.FILE_FID}${registeredSepa}`} target="_blank" className="button-link">Voir fichier transmis</a>
                        }
                        <a href={`${DOMAIN.URL_WEB}${RESSOURCE.DOCS_FID}sepa-inedis.pdf`} className="button-primary" target="_blank">Télécharger SEPA</a>
                        <label htmlFor="file_sepa" className="button-primary">Transmettre</label>
                        <input
                            type="file"
                            id="file_sepa"
                            onChange={(e => {
                                handleFileChange(e, setSepaFile, 'fid_fichier_sepa')
                            })}
                            style={{ display: "none" }}
                        />
                    </div>

                    <div className="legal-files-container">
                        <p>{registeredRib && <CheckIcon />} RIB</p>
                        {
                            registeredRib && <a href={`${DOMAIN.URL_WEB}${RESSOURCE.FILE_FID}${registeredRib}`} target="_blank" className="button-link">Voir fichier transmis</a>
                        }
                        <label htmlFor="file_rib" className="button-primary">Transmettre</label>
                        <input
                            type="file"
                            id="file_rib"
                            onChange={(e => {
                                handleFileChange(e, setRibFile, 'fid_fichier_rib')
                            })}
                            style={{ display: 'none' }}
                        />
                    </div>

                    <div className="legal-files-container">
                        <p>{registeredDecharge && <CheckIcon />} Décharge (Facultatif)</p>
                        {
                            registeredDecharge &&
                            <a href={`${DOMAIN.URL_WEB}${RESSOURCE.FILE_FID}${registeredDecharge}`} target="_blank" className="button-link">Voir fichier transmis</a>
                        }

                        <a href={`${DOMAIN.URL_WEB}${RESSOURCE.DOCS_FID}decharge.pdf`} className="button-primary" target="_blank">Télécharger Décharge</a>
                        <label htmlFor="file_decharge" className="button-primary">Transmettre</label>
                        <input
                            type="file"
                            id="file_decharge"
                            onChange={(e => {
                                handleFileChange(e, setDechargeFile, 'fid_fichier_decharge')
                            })}
                            style={{ display: "none" }}
                        />
                    </div>

                    {
                        statusForm && statusForm <= 1 ?
                            <>
                                <div className="legal-notice-container">
                                    <label>
                                        <input type="checkbox" checked={legalNotice} onChange={(e) => setLegalNotice(e.target.checked)} required />
                                        J'accepte les <a href="https://www.agence-is-com.fr/wp-content/uploads/2024/02/CGV-IS-communication-Mars-2023.pdf" target="_blank">Conditions Générales de Vente</a> et je reconnais avoir pris connaissance de la politique de confidentialité.
                                    </label>
                                </div>

                                <div className="error-container">
                                    {
                                        invalidFields && invalidFields.length > 0 && invalidFields.map((invalidField, index) => {
                                            return (
                                                <p>{invalidField.message}</p>
                                            )
                                        })
                                    }
                                </div>

                                <div>
                                    <button
                                        className="button-secondary"
                                        disabled={validForm < 1}
                                        onClick={() => handleSubmitForm()}
                                    >
                                        Valider la commande
                                    </button>
                                </div>
                            </> : <p>Vous êtes déjà inscrit au programme de fidélité</p>

                    }
                </FormBox>
            </div>
        </div>

    </FideliteLayout>
}

export default FideliteInscription;